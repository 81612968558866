export const betaConfigNA = {
  heimdallBaseURL: 'https://api.heimdall.integ.rme.logistics.a2z.com',
  heimdallRegion: 'us-east-1',

  utilityServiceBaseURL:
    'https://utilityservice.na.api.beta.otto.rme.amazon.dev',
  utilityServiceRegion: 'us-east-1',
  ottoOverviewBaseUrl: 'https://overview.na.api.beta.otto.rme.amazon.dev',
  ottoOverviewRegion: 'us-east-1',
  ottoRecommendationManagementServiceBaseUrl:
    'https://orms.us-east-1.api.beta.otto.rme.amazon.dev',
  ottoRecommendationManagementServiceRegion: 'us-east-1',

  cognitoAuthRegion: 'us-east-1',
  cognitoAuthDomain: 'https://otto-apm-beta.auth.us-east-1.amazoncognito.com',
  cognitoAuthIdentityPoolID: 'us-east-1:8b0cf0db-9f2f-4a8f-9d7a-02314c502d90',
  cognitoAuthUserPoolID: 'us-east-1_QrzQFuSnT',
  cognitoAuthUserPoolWebClientID: '4i72f344t5i4lsqjcbgmn2kuaf',
  kmsGeneratorKeyID:
    'arn:aws:kms:us-east-1:981693388957:key/5da43ded-3916-43c8-92f9-bc5e923489a1',

  // Cloudwatch RUM
  cloudwatchGuestRoleArn:
    'arn:aws:iam::817864394817:role/RUM-Monitor-us-east-1-817864394817-6157168651961-Unauth',
  cloudwatchIdentityPoolId: 'us-east-1:26c9fc55-cc74-437b-a74b-1137335b78ca',
  cloudwatchEndpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
  cloudwatchAppId: '37a28221-8234-4f99-a697-577f803ec53c',
  cloudwatchAppRegion: 'us-east-1',

  // App config data
  s3BucketName: 'otto-ui-config-beta',
  s3BucketRegion: 'us-east-1',

  // APM
  userOrgsGridName: 'SUOTAM',
  workOrderTypeToScreenIdMapGridName: 'WUTSCR',
};
