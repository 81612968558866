import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '@/configs/storeConfig/storeHooks';
import { appRoutes } from '@/library/constants/appRoutes';

function PublicAppLayout(): ReactElement {
  const { eamId } = window.userSessionDetails;

  const userDetails = useAppSelector(
    state => state.masterDataReducer.userDetails,
  );

  const isAuthenticated =
    userDetails?.userId && (!eamId || eamId === userDetails.sessionId);

  return !isAuthenticated ? (
    <div className="public-app-layout-container">
      <Outlet />
    </div>
  ) : (
    <Navigate
      to={appRoutes.overview}
      replace
    />
  );
}

export default PublicAppLayout;
