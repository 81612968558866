import { betaConfigEU } from './betaConfigEU';
import { betaConfigNA } from './betaConfigNA';
import { developmentConfigEU } from './developmentConfigEU';
import { developmentConfigNA } from './developmentConfigNA';
import { gammaConfigEU } from './gammaConfigEU';
import { gammaConfigNA } from './gammaConfigNA';
import { prodConfigEU } from './prodConfigEU';
import { prodConfigNA } from './prodConfigNA';

export default {
  betaConfigEU,
  betaConfigNA,
  developmentConfigEU,
  developmentConfigNA,
  gammaConfigEU,
  gammaConfigNA,
  prodConfigEU,
  prodConfigNA,
};
