import React, { ReactElement, useCallback, useState } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '@/configs/storeConfig/storeHooks';
import Calendar from '@/library/components/atoms/Calendar';
import Icon from '@/library/components/atoms/Icon';
import Modal from '@/library/components/atoms/Modal';
import { appConstants } from '@/library/constants/appConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import {
  editWORecommendation,
  fetchTechniciansConfigurationForWO,
} from '@/library/storeSlices/scheduleWOData/scheduleWODataActions';
import { iFormattedRecommendationsData } from '@/library/storeSlices/scheduleWOData/scheduleWODataTypes';
import {
  addNoOfDaysToDate,
  formatDate,
  getDateUsingTimeZoneOffSet,
  getUTCDate,
  getUTCDateByDateString,
} from '@/library/utils/dateUtils';
import { InlineOverwriteTriggerButton } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/TableInlineFields/InlineOverwriteTriggerButton';
import { constructTechConfigurationQueryParams } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersUtils';

interface iDateOverwriteInputProps {
  woData: iFormattedRecommendationsData;
}

export function ScheduleStartDateOverwriteInput(
  props: iDateOverwriteInputProps,
): ReactElement {
  const { woData } = props;
  const [modalVisible, setModalVisible] = useState(false);

  const dispatch = useAppDispatch();

  const { techniciansConfigListForDate, recommendationsMetadata } =
    useAppSelector(state => state.scheduleWODataReducer);

  const { ottoAppConfig, activeSite } = useAppSelector(
    state => state.masterDataReducer,
  );

  const updateWOData = (woData): void => {
    dispatch(editWORecommendation(woData));
  };

  const handleOverwriteValue = (value): void => {
    const newStartDate = value;
    if (newStartDate !== woData.scheduledStartDate) {
      updateWOData({
        ...woData,
        scheduledStartDate: newStartDate,
        shift: '',
        secondaryOwnersShifts: [],
        workOrderOwner: '',
        secondaryOwners: [],
      });
      if (
        !techniciansConfigListForDate[woData.workOrderID]?.[newStartDate]
          ?.length
      ) {
        dispatch(
          fetchTechniciansConfigurationForWO({
            ...constructTechConfigurationQueryParams(woData),
            techAvailabilityDate: newStartDate,
          }),
        );
      }
    }
    setModalVisible(false);
  };

  const datePlaceholder = formatDate(
    getUTCDateByDateString(woData.scheduledStartDate),
    appConstants.uiDateFormat,
  );

  const dismissModal = useCallback((): void => setModalVisible(false), []);

  const minDate = getDateUsingTimeZoneOffSet(
    getUTCDate(),
    activeSite?.utcOffset ?? 0,
  );
  const maxDate = addNoOfDaysToDate(
    getUTCDateByDateString(recommendationsMetadata.modelRunDate ?? ''),
    ottoAppConfig.numberOfDatesForSchedulingWorkOrder - 1,
  );

  const displayModal = useCallback((): void => {
    setModalVisible(true);
  }, [woData.scheduledStartDate]);

  return (
    <>
      <Modal
        visible={modalVisible}
        onDismiss={dismissModal}
        header={`${uiStrings.overwrite} ${uiStrings.scheduleDate} [${uiStrings.workOrderAbbreviation} #${woData.workOrderID}]`}
      >
        <div className="overwrite-calendar">
          <Calendar
            value={
              woData.scheduledStartDate ||
              formatDate(minDate, appConstants.dateFormat)
            }
            nextMonthAriaLabel=""
            previousMonthAriaLabel=""
            todayAriaLabel=""
            onChange={({ detail: { value } }) => handleOverwriteValue(value)}
            minDate={minDate}
            maxDate={maxDate}
          />
          <span className="overwrite-recommended-value">
            <p>
              {uiStrings.selectDateBetween}:{' '}
              {`${formatDate(minDate, '{DD} {MMM} {YYYY}')} ${
                uiStrings.and
              } ${formatDate(maxDate, '{DD} {MMM} {YYYY}')}`}
            </p>
            <br />
            {!!woData.recommendedScheduledStartDate && (
              <p>
                {uiStrings.ottoRecommendedData}:{' '}
                {formatDate(
                  getUTCDateByDateString(woData.recommendedScheduledStartDate),
                  appConstants.uiDateFormat,
                )}
              </p>
            )}
          </span>
        </div>
      </Modal>
      <InlineOverwriteTriggerButton
        value={woData.scheduledStartDate ? datePlaceholder : ''}
      >
        <div
          className="schedule-start-date"
          onClick={displayModal}
        >
          <span
            className={`schedule-start-date-text ${
              !woData.scheduledStartDate && 'placeholder-date'
            }`}
          >
            {woData.scheduledStartDate ? datePlaceholder : uiStrings.dateFormat}
          </span>{' '}
          <Icon name="calendar" />
        </div>
      </InlineOverwriteTriggerButton>
    </>
  );
}
