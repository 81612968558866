import { AwsRum, AwsRumConfig } from 'aws-rum-web';

import { store } from '@/configs/storeConfig/storeConfig';
import { envData, getCurrentRegion } from '@/library/utils/environmentUtils';

export let awsRum: AwsRum;

export const initAwsRum = (): void => {
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: envData.cloudwatchGuestRoleArn,
      identityPoolId: envData.cloudwatchIdentityPoolId,
      endpoint: envData.cloudwatchEndpoint,
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      sessionEventLimit: 0,
      enableXRay: true,
    };

    const APPLICATION_ID: string = envData.cloudwatchAppId;
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = envData.cloudwatchAppRegion;
    awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config,
    );
  } catch (error) {}
};

export const recordEvent = (
  eventName,
  eventData?: any,
  preventDefaultParams?: boolean,
): void => {
  if (preventDefaultParams) {
    awsRum?.recordEvent(eventName, {
      ...eventData,
    });
  } else {
    const {
      masterDataReducer: { activeSite, userDetails },
    } = store.getState();
    const { tenant } = window.userSessionDetails;
    awsRum?.recordEvent(eventName, {
      ...eventData,
      currentSite: activeSite?.siteId,
      userDetails,
      currentPage: location.pathname,
      tenant,
      region: getCurrentRegion(),
    });
  }
};
