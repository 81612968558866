import { nanoid } from '@reduxjs/toolkit';

import {
  iFormattedRecommendationsData,
  iWOTypeToAPMScreenIDMapping,
} from '@/library/storeSlices/scheduleWOData/scheduleWODataTypes';

export const callExtJSAPI = (
  url,
  params,
  metaData = {},
  requestExcel = false,
): Promise<any> => {
  return new Promise(resolve => {
    const requestId = nanoid();

    const onReceiveResponse = (data): void => {
      if (data.data?.metaData?.requestId === requestId) {
        window.removeEventListener('message', onReceiveResponse);
        resolve(data);
      }
    };

    window.addEventListener('message', onReceiveResponse);

    window.parent.postMessage(
      {
        action: requestExcel ? 'requestWithCustomCallback' : 'request',
        requestParams: {
          url,
          params,
          async: true,
        },
        metaData: {
          ...metaData,
          requestId,
        },
      },
      '*',
    );
  });
};

export const openAPMWorkOrderScreenPopUp = (
  workOrderDetails: iFormattedRecommendationsData,
  woTypeToAPMScreenIdMapping: iWOTypeToAPMScreenIDMapping[],
): void => {
  const { woScreenID = 'WSJOBS', dataspyID = '' } =
    woTypeToAPMScreenIdMapping.find(
      woToScreenIdMap => woToScreenIdMap.woType === workOrderDetails.woType,
    ) ?? {};

  window.parent.postMessage(
    {
      action: 'openScreenAsPopup',
      requestParams: [
        'loadmain?' +
          'initpath=WSJOBS&' +
          'SYSTEM_FUNCTION_NAME=WSJOBS&' +
          'USER_FUNCTION_NAME=' +
          woScreenID +
          '&' +
          'MENU_MODULE_KEY=-1&' +
          'fromlogin=yes&' +
          'popup=TRUE&' +
          'skipfirstfunccheck=true&' +
          'CURRENT_TAB_NAME=HDR&' +
          'hyperlinksource=F&' +
          'uitheme=ux3',
        [
          'hyperlink',
          1,
          'Q:WEBL',
          'COMPONENT_INFO_TYPE=HEAD_DATA&' +
            'DATASPY_ID=' +
            dataspyID +
            '&' +
            'MADDON_FILTER_ALIAS_NAME_1=workordernum&' +
            'MADDON_FILTER_OPERATOR_1=' +
            encodeURIComponent('=') +
            '&' +
            'MADDON_FILTER_JOINER_1=AND&' +
            'MADDON_FILTER_SEQNUM_1=1&' +
            'MADDON_FILTER_VALUE_1=' +
            workOrderDetails.workOrderID +
            '&' +
            'ONLY_DATA_REQUIRED=true&' +
            'showRecordView=true&' +
            'ADDONS_REQUIRED=true&',
        ],
      ],
    },
    '*',
  );
};

export interface iUserSessionDetails {
  userId: string;
  eamId: string;
  internalEAMId: string;
  tenant: string;
}

export const getUserSessionDetailsFromAPM = (): Promise<{
  data: { responseData: iUserSessionDetails };
}> => {
  return new Promise(resolve => {
    const requestId = nanoid();

    const onReceiveResponse = (data): void => {
      if (data.data?.metaData?.requestId === requestId) {
        window.removeEventListener('message', onReceiveResponse);
        resolve(data);
      }
    };

    window.addEventListener('message', onReceiveResponse);

    window.parent.postMessage(
      {
        action: 'getUserSessionDetails',
        metaData: {
          requestId,
        },
      },
      '*',
    );
  });
};
