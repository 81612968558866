import React from 'react';
import ReactDOM from 'react-dom/client';

import { configureAmplifyServices } from '@/configs/apiServiceConfig/apiServiceConfig';
import { initAwsRum, recordEvent } from '@/configs/metricsConfig/metricsConfig';
import { metricNames } from '@/library/constants/metricConstants';
import {
  getUserSessionDetailsFromAPM,
  iUserSessionDetails,
} from '@/library/utils/extJSUtils';

import RootComponent from './RootComponent';
import reportWebVitals from './reportWebVitals';

import './index.scss';

declare global {
  interface Window {
    userSessionDetails: iUserSessionDetails;
  }
}

configureAmplifyServices();
initAwsRum();

export const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement as Element);

const { data } = await getUserSessionDetailsFromAPM();
window.userSessionDetails = data.responseData;
root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(data => {
  recordEvent(`${metricNames.webVitals_}${data.name}`, data);
});
