import { appConstants } from '@/library/constants/appConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import {
  iFormattedRecommendationsData,
  iRecommendationGenerationStatus,
  iShiftLaborRollupData,
  iSiteMisconfigurationData,
  iTechBandwidth,
  iWOErrors,
  iWOTableErrorsData,
} from '@/library/storeSlices/scheduleWOData/scheduleWODataTypes';
import {
  formatDate,
  getDaysDifferenceBetweenDates,
  getUTCDateByDateString,
} from '@/library/utils/dateUtils';
import { roundOffToDecimalPlaces } from '@/library/utils/numberUtils';
import { formUiStringWithParameter } from '@/library/utils/stringUtils';
import { tableColumnConfig } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersFrames/ScheduleWorkOrderTable/tableColumnConfig';

export const getWorkOrdersTableErrorsData = (
  woRecommendations,
  shiftLaborRollupData,
): iWOTableErrorsData => {
  const woTableErrors = {};
  let totalWOsWithErrors = 0;
  woRecommendations.forEach(wo => {
    const woErrors = getWorkOrderErrors(wo, shiftLaborRollupData);
    woTableErrors[wo.workOrderID] = woErrors;
    woErrors.count && totalWOsWithErrors++;
  });
  return {
    ...woTableErrors,
    totalWOsWithErrors: { count: totalWOsWithErrors, details: [] },
  };
};

export const defaultWOSortingComparator = (a, b): number => {
  if (a.precedence === b.precedence) {
    return a.workOrderID > b.workOrderID ? -1 : 1;
  }
  if (!a.precedence) return 1;
  if (!b.precedence) return -1;
  return a.precedence > b.precedence ? -1 : 1;
};

export const formatRecommendationsData = (
  woRecommendations,
  ottoAppConfig,
): iFormattedRecommendationsData[] => {
  return woRecommendations
    .map(wo => {
      const scheduleStartDate = wo.scheduleStartDate;

      const secondaryOwners: string[] = [];
      const secondaryOwnersShifts: string[] = [];

      wo.additionalTechs.forEach(techDetails => {
        secondaryOwners.push(techDetails.tech);
        secondaryOwnersShifts.push(techDetails.shift);
      });

      return {
        excludedFromRollUpCalculation: wo.excludedFromRollUpCalculation,
        workOrderID: Number(wo.workOrderId),
        scheduledStartDate: scheduleStartDate,
        recommendedScheduledStartDate: scheduleStartDate,
        shift: scheduleStartDate ? wo.shift : null,
        recommendedShift: scheduleStartDate ? wo.shift : null,
        secondaryOwnersShifts,
        recommendedSecondaryOwnersShifts: secondaryOwnersShifts,
        recommendedWorkOrderOwner:
          scheduleStartDate && wo.shift ? wo.woOwner : null,
        workOrderOwner: scheduleStartDate && wo.shift ? wo.woOwner : null,
        recommendedSecondaryOwners: secondaryOwners,
        secondaryOwners,
        recommendedSchedulingBlock:
          ottoAppConfig.schedulingBlockReplacements[wo.schedulingBlock] ??
          wo.schedulingBlock,
        schedulingBlock:
          ottoAppConfig.schedulingBlockReplacements[wo.schedulingBlock] ??
          wo.schedulingBlock,
        ottoStatus: wo.ottoStatus,
        ottoStatusIcon: ottoAppConfig.workOrderOttoStatusTypes[wo.ottoStatus],
        estimatedHours: Number(wo.estimatedLabor),
        requiredTechniciansCount: Number(wo.peopleRequired),
        workOrderDesc: wo.woDescription,
        equipmentAlias: wo.equipmentAlias,
        equipmentDescription: wo.equipmentDescription,
        precedence: roundOffToDecimalPlaces(wo.precedenceScore ?? 0, 2),
        woType: wo.woType,
        pmIntrusiveType: wo.pmIntrusiveType,
        woStatus: wo.woStatus,
        unassignedReason:
          ottoAppConfig.unassignedReasonCodeToDescriptionMapping[
            wo.unassignedReason
          ],
        assets: wo.assets,
        isEdited: wo.isEdited ?? false,
        activity: wo.activity,
        dueDate: wo.dueDate
          ? formatDate(new Date(wo.dueDate), appConstants.dateFormat)
          : '',
        criticality: wo.criticality,
        requiredPreferences: wo.requiredPreferences,
        trade: wo.trade,
        overwriteReasons: [],
        overwriteReasonComment: '',
      };
    })
    .sort(defaultWOSortingComparator);
};

export const getWorkOrderErrors = (wo, shiftLaborRollupData): iWOErrors => {
  const errors: iWOErrors = { count: 0, details: [] };
  if (!wo.scheduledStartDate) {
    errors.count++;
    errors.details.push({
      columnId: tableColumnConfig.schStartDate.id,
      message: uiStrings.startDateIsMandatory,
    });
  }

  const secondaryOwnersShiftsCount = wo.secondaryOwnersShifts.reduce(
    (acc, curr) => (curr ? ++acc : acc),
    0,
  );

  if (
    !wo.shift ||
    secondaryOwnersShiftsCount < wo.requiredTechniciansCount - 1
  ) {
    !wo.shift && errors.count++;
    if (secondaryOwnersShiftsCount < wo.requiredTechniciansCount - 1) {
      errors.count =
        errors.count +
        (wo.requiredTechniciansCount - 1 - secondaryOwnersShiftsCount);
    }
    errors.details.push({
      columnId: tableColumnConfig.shift.id,
      message: formUiStringWithParameter(
        uiStrings.selectShiftsForAllTechnicians,
        { techCount: wo.requiredTechniciansCount - 1 },
      ),
    });
  }

  const secondaryOwnersCount = wo.secondaryOwners.reduce(
    (acc, curr) => (curr ? ++acc : acc),
    0,
  );

  if (
    !wo.workOrderOwner ||
    secondaryOwnersCount !== wo.requiredTechniciansCount - 1
  ) {
    !wo.workOrderOwner && errors.count++;
    if (secondaryOwnersCount < wo.requiredTechniciansCount - 1) {
      errors.count =
        errors.count + (wo.requiredTechniciansCount - 1 - secondaryOwnersCount);
    }

    let message = '';
    if (
      !wo.workOrderOwner &&
      secondaryOwnersCount !== wo.requiredTechniciansCount - 1
    ) {
      message = formUiStringWithParameter(
        uiStrings.allTechniciansMustBeSelected,
        { techCount: wo.requiredTechniciansCount - 1 },
      );
    } else if (!wo.workOrderOwner) {
      message = uiStrings.woOwnerMustBeSelected;
    } else if (secondaryOwnersCount !== wo.requiredTechniciansCount - 1) {
      message =
        wo.requiredTechniciansCount -
        1 +
        ' ' +
        uiStrings.secondaryOwnersMustBeSelected;
    }
    errors.details.push({
      columnId: !wo.workOrderOwner
        ? tableColumnConfig.primaryOwner.id
        : tableColumnConfig.secondaryOwner.id,
      message,
    });
  }

  if (
    ((shiftLaborRollupData[wo.scheduledStartDate]?.[wo.shift] &&
      !isShiftLaborRollupValid(
        shiftLaborRollupData[wo.scheduledStartDate][wo.shift][
          wo.workOrderOwner
        ],
      )) ||
      wo.secondaryOwners.some(
        (tech, index) =>
          shiftLaborRollupData[wo.scheduledStartDate][
            wo.secondaryOwnersShifts[index]
          ] &&
          !isShiftLaborRollupValid(
            shiftLaborRollupData[wo.scheduledStartDate][
              wo.secondaryOwnersShifts[index]
            ][tech],
          ),
      )) &&
    !wo.excludedFromRollUpCalculation
  ) {
    errors.count++;
    errors.details.push({
      columnId: tableColumnConfig.shiftLaborRollup.id,
      message: uiStrings.shiftLaborRollupMustNotExceed,
    });
  }
  return errors;
};

export const getShiftLaborRollupData = (
  woRecommendations: iFormattedRecommendationsData[],
  techBandwidthData,
): iShiftLaborRollupData => {
  function getTechnicianRollupDataObject(
    technician,
    shift,
    { estimatedHours, requiredTechniciansCount, scheduledStartDate },
  ): iShiftLaborRollupData {
    const allocationHoursPerTechnician =
      estimatedHours / requiredTechniciansCount;

    const maximumAllowedHoursForTechnician =
      techBandwidthData[scheduledStartDate]?.[shift]?.[technician] ?? 0;

    const newAllocatedHours =
      (shiftLaborRollupData[scheduledStartDate][shift][technician]
        ?.allocatedHours ?? 0) + allocationHoursPerTechnician;

    return scheduledStartDate && technician
      ? {
          [technician]: {
            allocatedHours: newAllocatedHours,
            maximumAllowedHours: maximumAllowedHoursForTechnician,
            shiftLaborRollup:
              newAllocatedHours / maximumAllowedHoursForTechnician,
          },
        }
      : {};
  }

  function updateShiftLaborRollupData(shift, owner, wo): void {
    if (wo.scheduledStartDate) {
      shiftLaborRollupData[wo.scheduledStartDate] =
        shiftLaborRollupData[wo.scheduledStartDate] ?? {};

      shiftLaborRollupData[wo.scheduledStartDate][shift] =
        shiftLaborRollupData[wo.scheduledStartDate][shift] ?? {};

      shiftLaborRollupData[wo.scheduledStartDate][shift] = {
        ...shiftLaborRollupData[wo.scheduledStartDate][shift],
        ...getTechnicianRollupDataObject(owner, shift, wo),
      };
    }
  }

  const shiftLaborRollupData = {};

  Object.keys(techBandwidthData).forEach(startDate => {
    Object.keys(techBandwidthData[startDate]).forEach(shift => {
      Object.keys(techBandwidthData[startDate][shift]).forEach(tech => {
        updateShiftLaborRollupData(shift, tech, {
          estimatedHours: 0,
          requiredTechniciansCount: 1,
          scheduledStartDate: startDate,
        });
      });
    });
  });
  woRecommendations.forEach(wo => {
    if (!wo.excludedFromRollUpCalculation) {
      if (wo.shift) {
        updateShiftLaborRollupData(wo.shift, wo.workOrderOwner, wo);
      }
      wo.secondaryOwnersShifts.forEach((secondaryShift, index) =>
        updateShiftLaborRollupData(
          secondaryShift,
          wo.secondaryOwners[index],
          wo,
        ),
      );
    }
  });

  return shiftLaborRollupData;
};

export const isShiftLaborRollupValid = (
  { allocatedHours, maximumAllowedHours } = {
    allocatedHours: 0,
    maximumAllowedHours: 0,
  },
): boolean =>
  roundOffToDecimalPlaces(allocatedHours, 2) <=
  roundOffToDecimalPlaces(maximumAllowedHours, 2);

export const getFilteredListOfWOs = (
  appliedFilters,
  woTableErrors,
  woRecommendationsMasterData,
): iFormattedRecommendationsData[] => {
  const {
    shift,
    woOwner,
    schBlock,
    ottoStatus,
    schStartDate,
    unassignedReasons,
    hideErrors,
    dueDate,
  } = appliedFilters;

  const isWorkOrderFiltered = (wo): boolean => {
    return (
      (!schStartDate.length ||
        schStartDate.some(data =>
          data.value
            ? data.value === wo.scheduledStartDate
            : !wo.scheduledStartDate,
        )) &&
      (!dueDate.length ||
        dueDate.some(data => {
          if (!data.value) return !wo.dueDate;
          const comparatorDate = data.value.split('~')[1];
          if (data.value.includes('past~')) {
            return (
              getDaysDifferenceBetweenDates(
                getUTCDateByDateString(wo.dueDate),
                getUTCDateByDateString(comparatorDate),
              ) > 0
            );
          } else if (data.value.includes('beyond~')) {
            return (
              getDaysDifferenceBetweenDates(
                getUTCDateByDateString(wo.dueDate),
                getUTCDateByDateString(comparatorDate),
              ) < 0
            );
          }
          return data.value === wo.dueDate;
        })) &&
      (!shift.length ||
        shift.some(data => data.value === wo.shift) ||
        wo.secondaryOwnersShifts.some(shiftId =>
          shift.some(data => data.value === shiftId),
        )) &&
      (!unassignedReasons.length ||
        unassignedReasons.some(data => data.value === wo.unassignedReason)) &&
      (!schBlock.length ||
        schBlock.some(data => data.value === wo.schedulingBlock)) &&
      (!ottoStatus.length ||
        ottoStatus.some(data => data.value === wo.ottoStatus)) &&
      (!woOwner.length ||
        woOwner.some(data => data.value === wo.workOrderOwner) ||
        wo.secondaryOwners?.some(tech =>
          woOwner.some(data => data.value === tech),
        )) &&
      (!hideErrors.length || !woTableErrors[wo.workOrderID].count)
    );
  };

  const filteredList: iFormattedRecommendationsData[] = [];
  filteredList.push(...woRecommendationsMasterData.filter(isWorkOrderFiltered));
  return filteredList;
};

export const getFormattedTechBandwidthData = (
  techBandwidthData,
): iTechBandwidth => {
  const formattedData = {};
  techBandwidthData.forEach(techBandwidth => {
    formattedData[techBandwidth.scheduleStartDate] = {
      ...(formattedData[techBandwidth.scheduleStartDate] ?? {}),
    };
    formattedData[techBandwidth.scheduleStartDate][techBandwidth.shift] = {
      ...(formattedData[techBandwidth.scheduleStartDate][techBandwidth.shift] ??
        {}),
      [techBandwidth.tech]: techBandwidth.laborHours,
    };
  });
  return formattedData;
};

export const formatSiteMisconfigurationData = (
  misconfiguredEntitiesData,
  ignoredErrorCodes: string[],
): iSiteMisconfigurationData => {
  const hardCheckCodes: string[] = [];
  const softCheckCodes: string[] = [];
  let genericErrorMessage: string = '';
  misconfiguredEntitiesData.forEach(entitiesData => {
    entitiesData.data.forEach(data => {
      if (!ignoredErrorCodes.includes(data.code)) {
        if (data.isHardCheck) {
          hardCheckCodes.push(data.code);
        } else {
          softCheckCodes.push(data.code);
        }
      }
    });
    if (entitiesData.entityType === 'GENERIC') {
      genericErrorMessage = entitiesData.data[0].message;
    }
  });
  return {
    hardCheckCodes,
    softCheckCodes,
    genericErrorMessage,
  };
};

export const formatRecommendationGenerationData = (
  response,
  modelRunDate,
): iRecommendationGenerationStatus => {
  let isNewRunAvailable = false;
  if (response.lastSuccessfulRunDetail?.runDate) {
    isNewRunAvailable =
      modelRunDate !== response.lastSuccessfulRunDetail?.runDate;
  }
  const isNewRunInProgress = response.rgStatus === 'IN_PROGRESS';
  const isNewRunFailed = response.rgStatus === 'FAILED';
  return {
    runStatus: response.rgStatus,
    lastSuccessfulRunDate: response.lastSuccessfulRunDetail?.runDate,
    isNewRunInProgress,
    isNewRunAvailable,
    isNewRunFailed,
  };
};
