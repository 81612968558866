import {
  heimdallAPI,
  overviewServiceAPI,
} from '@/configs/apiServiceConfig/apiServiceConfig';
import { apiNames, apiRoutes } from '@/library/constants/apiConstants';
import { authSignIn } from '@/library/utils/authUtils';
import { AppRegions, getCurrentRegion } from '@/library/utils/environmentUtils';

export const validateAPMSessionIdAndGetUserDetails = (): Promise<any> => {
  const { userId, eamId, tenant } = window.userSessionDetails;
  const bodyParams = {
    userId,
    clientId: getCurrentRegion() === AppRegions.NA ? 'OTTO_APM' : 'OTTO_EU_APM',
    sessionId: eamId,
  };
  return userId && eamId
    ? heimdallAPI
        .post(
          {
            name: apiNames.validateAPMSessionIdAndGetUserDetails,
            route: apiRoutes.validateAPMSessionIdAndGetUserDetails,
          },
          bodyParams,
        )
        .then(async response => {
          await authSignIn(response?.userDetails?.email ?? userId, eamId);
          return { ...response, tenantId: tenant };
        })
    : Promise.reject(Error(''));
};

export const getOttoSites = (): Promise<any> =>
  overviewServiceAPI.get({
    name: apiNames.ottoSites,
    route: apiRoutes.ottoSites,
  });
