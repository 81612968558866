export const developmentConfigEU = {
  heimdallBaseURL: 'https://api.heimdall.eu.integ.rme.logistics.a2z.com',
  heimdallRegion: 'eu-west-1',

  utilityServiceBaseURL:
    'https://utilityservice.eu.api.beta.otto.rme.amazon.dev',
  utilityServiceRegion: 'eu-west-1',
  ottoOverviewBaseUrl: 'https://overview.eu.api.beta.otto.rme.amazon.dev',
  ottoOverviewRegion: 'eu-west-1',
  ottoRecommendationManagementServiceBaseUrl:
    'https://orms.eu-west-1.api.beta.otto.rme.amazon.dev',
  ottoRecommendationManagementServiceRegion: 'eu-west-1',

  cognitoAuthRegion: 'eu-west-1',
  cognitoAuthDomain:
    'https://otto-apm-beta-eu.auth.eu-west-1.amazoncognito.com',
  cognitoAuthIdentityPoolID: 'eu-west-1:2c4cf992-b524-4742-bedf-bc79d1d5e9ba',
  cognitoAuthUserPoolID: 'eu-west-1_VqRWCydpS',
  cognitoAuthUserPoolWebClientID: '4cjvscitr9t55q0b4i9s5vpurr',
  kmsGeneratorKeyID:
    'arn:aws:kms:us-east-1:981693388957:key/5da43ded-3916-43c8-92f9-bc5e923489a1',

  // Cloudwatch RUM
  cloudwatchGuestRoleArn:
    'arn:aws:iam::817864394817:role/RUM-Monitor-us-east-1-817864394817-2310762841961-Unauth',
  cloudwatchIdentityPoolId: 'us-east-1:f0c66607-c420-45cc-b34a-5836c6b78c7c',
  cloudwatchEndpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
  cloudwatchAppId: '0025357f-7a74-43ef-b440-229af41d3ae2',
  cloudwatchAppRegion: 'us-east-1',

  // App config data
  s3BucketName: 'otto-ui-config-beta-eu',
  s3BucketRegion: 'eu-west-1',

  // APM
  userOrgsGridName: 'SUOTAM',
  workOrderTypeToScreenIdMapGridName: 'WUTSCR',
};
